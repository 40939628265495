* {
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.custom-main-panel {
    height: fit-content !important;
    max-height: fit-content !important;
}

/* html:focus-within {
    scroll-behavior: smooth;
} */

body {
    position: relative !important;
}


.budget-table td {
    padding: 0 !important;
    /* width:5px !important; */
}

.account-table td {
    padding: 4px !important;
    font-size: 12px;
}


.big-width {
    max-width: 640px !important;
}

.sidebar .nav p {
    font-weight: 400 !important;
    font-size: 12px !important;
}

.sidebar[data-active-color="info"] .nav li.active>a {
    font-weight: bold !important;
}

.sidebar .nav i {
    font-size: 20px !important;
}

.sidebar .nav .fa-pencil-alt {
    font-size: 15px !important;
    /* visibility: hidden; */
}

a:hover {
    text-decoration: none !important;
}

.all-budget-cards {
    transition: all .15s linear !important;
    cursor: pointer;
}

.all-budget-cards:hover {
    opacity: 0.8 !important;
    background-color: #eaf5f7 !important;
}

.account-card {
    transition: all .15s linear !important;

}

.account-card:hover {
    background-color: var(--info) !important;
}

.account-card:hover p {
    color: white !important;
}




.hovered {
    background: #f4f4f4;
    border-style: dashed;
}

.avatar.profile-picture {
    width: 60px !important;
    height: 60px !important;
}

.card-stats .card-body .numbers {
    font-size: 1.4em !important;
}

.nav-logout-hover {
    color: black !important;

}

.nav-logout-hover:hover {
    color: 'white' !important;

}

.accordion-button {
    z-index: 0 !important;
}

.custom-btn {
    border-radius: 5px !important;
    margin-left: 10px;
    cursor: pointer;
    border-width: 0px !important;
}

.options-heading {
    font-weight: bold;
}

.custom-popover-body {
    width: 240px;
}

.cursor-pointer {
    cursor: pointer;
}

.vertically-center-items {
    height: 100px;
    line-height: 100px;
    margin-bottom: 0px;
}

/* React Big Calendar changes */

.rbc-row-content {

    min-height: 100px !important;
}

.birthdays-calendar .rbc-row-content {

    min-height: 40px !important;
}

.rbc-toolbar .rbc-toolbar-label {

    font-size: 12px !important;
}

.birthdays-calendar .rbc-btn-group:nth-child(3) {
    display: none !important;
}

.goals-card-body {
    padding-top: 0px !important;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.table .form-check label .form-check-sign::before,
.table .form-check label .form-check-sign::after {
    background: #E3E3E3;
    border: 0.1px solid #E3E3E3;
}

.table tr:hover .form-check label .form-check-sign::before,
.table tr:hover .form-check label .form-check-sign::after {
    background: white;
    color: #E3E3E3;
    border: 0.1px solid white;
}

.table tr:hover th .form-check label .form-check-sign::before,
.table tr:hover th .form-check label .form-check-sign::after {
    background: #E3E3E3;
    color: white;
    border: 0.1px solid #E3E3E3;
}

.accordion-item .accordion-button:focus {
    box-shadow: 0 0px 0px rgb(0 0 0 / 8%) inset, 0 0 0px rgb(126 239 104 / 60%);
}

.form-group .form-control,
.input-group .form-control {
    padding-left: 5px !important;
}

.search-wrapper {
    height: 35px;
    max-width: 200px;
}

.optionListContainer {
    max-width: 200px;
}

.parent-progress-bar {
    background-color: "#E3E3E3";
}

tr:hover .parent-progress-bar {
    background-color: white;
}

._3vt7_Mh4hRCFbp__dFqBCI li:hover {
    background: #34b5b8 !important;
}

.custom-nav-tabs .nav-tabs .nav-item .nav-link.active:after,
.custom-nav-tabs .nav-tabs .nav-item .nav-link.active:hover:after,
.custom-nav-tabs .nav-tabs .nav-item .nav-link.active:focus:after {
    border-bottom: unset !important;
}

.custom-nav-tabs-bottom .nav-tabs .nav-item .nav-link.active:after,
.custom-nav-tabs-bottom .nav-tabs .nav-item .nav-link.active:hover:after,
.custom-nav-tabs-bottom .nav-tabs .nav-item .nav-link.active:focus:after {
    display: none !important;
}

.custom-nav-tabs-bottom .nav-tabs .nav-item .nav-link.active:before,
.custom-nav-tabs-bottom .nav-tabs .nav-item .nav-link.active:hover:before,
.custom-nav-tabs-bottom .nav-tabs .nav-item .nav-link.active:focus:before {
    display: none !important;
}

@media screen and (max-width: 576px) {
    .sheet-left-items {
        justify-content: flex-start !important;
        margin-top: 20px;
    }
}

/* Credit Profile Dashboard */

.tabs-container {
    background-color: white;
    border-radius: 10px;
    height: 50px;
    /* box-shadow: 14px 15px 13px -12px rgb(0 0 0 / 39%); */
    display: flex;
    flex-direction: row;
}

.tabs-container .tab {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.tabs-container .active {
    background-color: #2AA9CC;
    color: white;
    transition: background-color 0.3s ease-in-out;
}

.tabs-container .tab .tab-icon {
    margin-right: 10px;
}

.tab-content {
    margin-top: 20px;
}

.in-card-tile {
    border: 1px solid silver;
    height: 200px;
    width: '50%';
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.in-card-tile:hover {
    background-color: #2AA9CC22;
    border: 1px solid #2AA9CC22;
}

.btn,
.add-goal-btn,
.budget-overview-title,
.shadow-input {
    box-shadow: 1px 1px 5px silver;
}

.btn:hover {
    box-shadow: 1px 1px 5px silver !important;
}

.btn:active {
    box-shadow: 1px 1px 5px silver !important;
}

.btn:focus {
    box-shadow: 1px 1px 5px silver !important;
}

.btn-danger {
    color: white !important;
}

.btn-link-cancel {
    color: black !important;
}

.btn-success,
.bg-success {
    background-color: #239B37 !important;
    color: white !important;
    opacity: 0.95;
}

.btn-success:hover {
    background-color: #239B37 !important;
}

.btn-success:active {
    background-color: #239B37 !important;
}

.btn-success:focus {
    background-color: #239B37 !important;
}


@media only screen and (max-width: 600px) {
    .tabs-container .tab .tab-label {
        display: none;
    }
}