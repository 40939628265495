.cleared-trans {
    color: #51CBCE;
}

.row-input {
    padding-left: 2px;
    padding-right: 2px;
}

.date-td {
    width: 120px;
}

.date-td input {
    width: 125px;
}

.css-g1d714-ValueContainer {
    width: 130px;
}

.checkbox-th {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px !important;
}

.checkbox-th .check-icon {
    font-size: 19px;
}

.flex-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 35px;
}

.save-icon {
    font-size: 18px;
    cursor: pointer;
}

.clear-icon {
    font-size: 15px;
    cursor: pointer;
}

.css-2b097c-container:focus-visible {
    outline: none;
}

.error-td .css-10ruwn1-control:hover,
.error-td .css-qfod3t-control:hover {
    border-color: #EF8157;
}

.custom-date-field {
    padding-left: 10px;
    padding-right: 0px;
}