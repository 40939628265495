.otp-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-text {
    color: #51CBCE;
    cursor: pointer;
}

.link-text:hover {
    text-decoration: underline;
}