.avatar-container {
    display: flex;
    justify-content: center;
}

.avatar-wrapper {
    position: relative;
}

span.add-btn {
    background: white;
    padding: 3px;
    border-radius: 50%;
    font-size: 13px;
    position: absolute;
    height: 20px;
    width: 20px;
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: black;
}

.profle-avatar {
    cursor: default;
    background-color: gray;
    object-fit: cover;
}

.avatar-input {
    display: none;
}