.reports-nodata-wrapper {
    min-height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nodata {
    font-size: 20px;
    font-weight: bold;
    color: silver;
}