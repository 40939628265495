.loader-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #000000aa;
    z-index: 9999;
}