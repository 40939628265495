.custom-photo {
    /* overflow: unset; */
    display: flex;
    justify-content: center;
    align-items: center;
}

a.logo-small {
    display: none;
}

li.active .sidebar-normal,
li.active p,
li.active i {
    color: #51CBCE !important;
}

@media (min-width: 991px) {
    .sidebar-mini .sidebar .logo a.logo-normal {
        display: none;
    }

    .sidebar-mini .sidebar .logo a.logo-small {
        display: block;
    }
}