.sticky-head {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid #DDDDDD;
    margin-top: 79px !important;
}

.custom-content {
    margin-top: 0px !important;
}