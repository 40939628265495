.add-budget-card {
    height: 350px !important;
}

.icon-flex {
    display: flex;
    justify-content: center;
}

.budget-icon-wrapper {
    border: 1px solid #D4D4D3;
    padding: 15px;
    border-radius: 50%;
}

svg.budget-icon {
    font-size: 55px;
}