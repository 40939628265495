.no-data-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 35px;
}

span.no-data {
    font-size: 18px;
    font-weight: bold;
    color: silver;
}

.expense-div {
    width: 25vw;
}

.left-btns {
    padding-left: 5px !important;
}

.divider {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 0.1px;
    background-color: silver;
}

.add-goal-btn {
    cursor: pointer;
}

.sticky-head {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid #DDDDDD;
    /* margin-right: 8px !important; */
    margin-top: 79px !important;
}

.custom-content {
    margin-top: 0px !important;
}

.shadow-input {
    margin-top: 10px;
}

.budget-container {
    border: 0px solid red;
}

.budget-col {
    display: flex;
    flex: 1 1;
    justify-content: center;
}

.budget-head {
    display: flex;
    border: 0px solid red;
    flex: 1;
    border-bottom: 1px solid #DEE2E6;
}

.budget-col1 {
    display: flex;
    border: 0px solid red;
    flex: 1 1;
    justify-content: center;
    align-items: center;
}

.budget-col2 {
    /* display: flex; */
    width: 100%;
    border: 0px solid red;
    flex: 8 1;
    margin-left: 0px !important;
}

.budget-col3 {
    display: flex;
    border: 0px solid red;
    flex: 2;
    justify-content: center;
    align-items: center;
}

.budget-col4 {
    display: flex;
    border: 0px solid red;
    flex: 2;
    justify-content: center;
    align-items: center;
}

.budget-col5 {
    display: flex;
    border: 0px solid red;
    flex: 2;
    justify-content: center;
    align-items: center;
}

.expense-target-text {
    border: 0px solid red;
    width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.expense-target {
    display: flex;
    padding: 0px !important;
    justify-content: space-between;
    align-items: center;

}

.expense-target-chip {
    margin: 0px !important;
}

.expense-progress {
    background-color: #DDDDDD !important;
    padding: 0px !important;
    margin-top: 5px;
    height: 6px;
    border-radius: 10px;
    overflow: hidden;
}

.progress-bar {
    background-color: #51cbce;
    height: 100%;
    border-radius: 10px;
}

.buget-input {
    padding-left: 5px !important;
}

.unspent-capsule {
    border-radius: 15px;
    height: 30px;
    min-width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.budget-env-col {
    justify-content: flex-start;
}

.accordion-body {
    padding: 5px;
}

.top-head {
    padding: 5px;
    border: 1px solid #DEE2E6;
    font-weight: bold;
}

.category-name {
    border: 0px solid red;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.spent-text {
    cursor: pointer;
}

.spent-text:hover {
    text-decoration: underline;
    color: #51cbce;
}

.custom-modal-body {
    padding: 10px 10px 0px 10px !important;
}

.custom-modal-header {
    padding: 0px !important;
}

.custom-modal-close {
    margin-top: -5px !important;
    margin-right: -5px !important;
}

.custom-transaction-td {
    font-size: 14px !important;
}

.checkbox-wrapper {
    height: 19px;
    width: 19px;
    background-color: #E3E3E3 !important;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.check-icon {
    font-size: 35px;
    color: #51cbce;
    cursor: pointer;
}